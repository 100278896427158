import axios from 'axios'
import store from '@/app/store'
import router from "@/app/router.js"
import NProgress from 'nprogress'
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';
import { setupCache } from 'axios-cache-adapter'

const cache = setupCache({
  maxAge: 30 * 1000,
  debug: false
})

const token = localStorage.getItem('user-token');
if (token) {
  axios.defaults.headers.common['Authorization'] = "Bearer " + token
}
var  API_ENDPOINT = process.env.VUE_APP_API_URL; 

 const AXIOS2 = axios.create({
 // baseURL: `/api`
  baseURL: API_ENDPOINT,
  headers: {
      'Access-Control-Allow-Origin': 'http://localhost:8081/*',
      'Access-Control-Allow-Methods': 'POST,GET',
      'Access-Control-Allow-Headers': '*',
      'Cache-Control': 'no-cache'
    },
    adapter: cache.adapter
  });


  AXIOS2.interceptors.request.use(config => {
    NProgress.start();
    const token = localStorage.getItem('user-token');
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }

    return config;
    },  (error) => {
    return Promise.reject(error);
    });

  AXIOS2.interceptors.response.use(response => {
    NProgress.done()
    return response
  }, (error) => {
    NProgress.done()
    return Promise.reject(error);
  })

  AXIOS2.interceptors.response.use(
    (resp) => {
      let v = resp.headers['vers'] || 'default'
      
      if(v !== localStorage.getItem('vers') && resp.config.method == 'get'){
        localStorage.setItem('vers', v)
        if (store.getters.isAuthenticated) {
        store.dispatch('logout');
        router.push("/app/signin?reasonToRedirect=security&redirectUrl="+window.location.pathname)
        //window.location.reload(true)
        }
        
       }

      let logOut = resp.headers['logout']
      if ( logOut != undefined &&  logOut != null ) {
        if (store.getters.isAuthenticated) {
          store.dispatch('logout');
          router.push("/app/signin?reasonToRedirect=license")
          }
          setTimeout(() => {
             AXIOS2.get('/auth/signouted/'+logOut).then(response => {})
            
          }, 1000);
      }
    return Promise.resolve(resp)
  })

AXIOS2.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if ("status" in error.response && 401 === error.response.status && error.response.data.path != "/api/v1/auth/signin") {
      store.dispatch('logout');
      router.push("/app/signin?reasonToRedirect=security&redirectUrl="+window.location.pathname)
    } else {
        return Promise.reject(error);
    }
});

var someId= 5;


const AXIOS3 = axios.create({
   baseURL: API_ENDPOINT +"camunda/proxy/",
   headers: {
       'Access-Control-Allow-Origin': 'http://localhost:8081/*',
       'Access-Control-Allow-Methods': 'POST,GET',
       'Access-Control-Allow-Headers': '*',
       'Cache-Control': 'no-cache'
     },
     adapter: cache.adapter
   });
 
 
   AXIOS3.interceptors.request.use(config => {
     NProgress.start();
     const token = localStorage.getItem('user-token');
     config.url= store.getters.getSelectedCamundaServer.id+config.url
     if (token) {
       config.headers.Authorization = "Bearer " + token;
     }

 
     return config;
     },  (error) => {
     return Promise.reject(error);
     });
 
     AXIOS3.interceptors.response.use(response => {
     NProgress.done()
     return response
   }, (error) => {
     NProgress.done()
     return Promise.reject(error);
   })
 
   AXIOS3.interceptors.response.use(
     (resp) => {
       let v = resp.headers['vers'] || 'default'
       
       if(v !== localStorage.getItem('vers') && resp.config.method == 'get'){
         localStorage.setItem('vers', v)
         if (store.getters.isAuthenticated) {
         store.dispatch('logout');
         router.push("/app/signin?reasonToRedirect=security&redirectUrl="+window.location.pathname)
         }
         
        }
     return Promise.resolve(resp)
   })
 
   AXIOS3.interceptors.response.use(function (response) {
     return response;
 }, function (error) {
     if ("status" in error.response && 401 === error.response.status && error.response.data.path != "/api/v1/auth/signin") {
       store.dispatch('logout');
       router.push("/app/signin?reasonToRedirect=security&redirectUrl="+window.location.pathname)
     } else {
         return Promise.reject(error);
     }
 });




export const excamad=AXIOS3
export const AXIOS=AXIOS2

